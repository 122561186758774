export default class AnatomyEntity {
  key: string

  constructor(
    public group: string,
    public anatomy: string | null = null,
    public position: string | null = null,
    public direction: string | null = null,
    public caption: string | null = null,
    public advanced: boolean | null = null,
    public priority: number | null = null
  ) {
    this.key = [group, anatomy, position, direction].filter(Boolean).join(" ")
  }
}
