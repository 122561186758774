import { AqsCustomization, AqsCustomizationCore } from "@/models/AqsCustomization"
import { CustomizationEntriesTree } from "@/models/CustomizationEntriesTree"
import axios from "axios"

export default class AqsCustomizationService {
  pageSize: number = 20

  getAqsCustomizations(
    page: number,
    sort: string,
    organizationId?: string,
    computerId?: string,
    userId?: string,
    pageSize: number = this.pageSize
  ) {
    let urlSearchParams = new URLSearchParams()
    urlSearchParams.append("page", page.toString())
    urlSearchParams.append("size", pageSize.toString())
    if (sort) urlSearchParams.append("sort", sort)
    if (organizationId) urlSearchParams.append("organizationId", organizationId)
    if (computerId) urlSearchParams.append("computerId", computerId)
    if (userId) urlSearchParams.append("userId", userId)

    const url = "aqscustomizations/support?" + urlSearchParams.toString()

    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }

  getAqsCustomizationByIdAndBlobType(
    aqsCustomizationId: string,
    blobType: string,
    ranking?: number
  ) {
    let url = `aqscustomizations/${aqsCustomizationId}/${blobType}`
    if (ranking != null) url += "?ranking=" + ranking
    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }

  async postAqsCustomization(
    supportLinked: { organizationId: string; computerId: string; userId: string },
    aqsCustomization: AqsCustomizationCore,
    body: CustomizationEntriesTree
  ) {
    let urlSearchParams = new URLSearchParams()
    urlSearchParams.append("blobType", "CUSTOMIZATION")
    urlSearchParams.append("type", aqsCustomization.type)
    urlSearchParams.append("species", aqsCustomization.species)
    urlSearchParams.append("alias", aqsCustomization.alias)
    if (supportLinked.organizationId)
      urlSearchParams.append("organizationId", supportLinked.organizationId)
    if (supportLinked.computerId) urlSearchParams.append("computerId", supportLinked.computerId)
    if (supportLinked.userId) urlSearchParams.append("userId", supportLinked.userId)

    const url = "aqscustomizations/support?" + urlSearchParams.toString()

    return await axios
      .post(url, body, {
        baseURL: process.env.VUE_APP_VSOL_API,
      })
      .then((res) => res.data)
  }

  async editAqsCustomization(aqsCustomizationId: string, aqsCustomization: AqsCustomizationCore) {
    const url = "aqscustomizations/" + aqsCustomizationId

    return await axios
      .put(url, aqsCustomization, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  async editAqsCustomizationBody(
    aqsCustomizationId: string,
    blobType: string,
    body: CustomizationEntriesTree,
    ranking?: number
  ) {
    let url = `aqscustomizations/${aqsCustomizationId}/${blobType}`
    if (ranking != null) url += "?ranking=" + ranking
    return await axios
      .put(url, body, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  deleteAqsCustomizations(aqsCustomizationIds: string[]) {
    let urlSearchParams = new URLSearchParams()
    aqsCustomizationIds.forEach((id: string) => {
      urlSearchParams.append("ids", id)
    })

    const url = "aqscustomizations?" + urlSearchParams.toString()
    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }

  async copyAqsCustomizations(
    aqsCustomizationIds: string[],
    organizationId: string,
    computerId: string,
    userId: string
  ) {
    let queryParams = new URLSearchParams()

    if (organizationId) queryParams.append("organizationId", organizationId)
    if (computerId) queryParams.append("computerId", computerId)
    if (userId) queryParams.append("userId", userId)

    const url = `aqscustomizations/copy/support?${queryParams.toString()}`
    return await axios.post(url, aqsCustomizationIds, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  async getAnatomyTree(species : string) {
    let url = `anatomies/tree?species=${species}`
    const res = await axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API })
    return res.data
  }
}
