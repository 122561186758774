import AnatomyEntity from "@/models/AnatomyEntity"
import { AnatomyTreeObject, Group, Anatomy, Position, Direction } from "@/models/AnatomyTreeObject"
import { CustomizationEntriesTree, Entry } from "@/models/CustomizationEntriesTree"

export default function useCustomizationsUtils() {
  function convertAnatomyTreeToArray(anatomyTreeObject: AnatomyTreeObject): Map<string, AnatomyEntity> {
    const result = new Map()

    anatomyTreeObject.groups.forEach((group: Group) => {
      const anatomyEntityGroup = new AnatomyEntity(group.name)
      result.set(anatomyEntityGroup.key, anatomyEntityGroup)
      group.anatomies.forEach((anatomy: Anatomy) => {
        const anatomyEntityAnatomy = new AnatomyEntity(group.name, anatomy.name)
        result.set(anatomyEntityAnatomy.key, anatomyEntityAnatomy)
        anatomy.positions.forEach((position: Position) => {
          const anatomyEntityPosition = new AnatomyEntity(group.name, anatomy.name, position.name)
          result.set(anatomyEntityPosition.key, anatomyEntityPosition)
          position.directions.forEach((direction: Direction) => {
            const anatomyEntityDirection = new AnatomyEntity(
              group.name,
              anatomy.name,
              position.name,
              direction.name
            )
            result.set(anatomyEntityDirection.key, anatomyEntityDirection)
          })
        })
      })
    })

    return result
  }

  function convertAnatomiesArrayToEntriesTree(anatomies: AnatomyEntity[]) {
    if (!anatomies?.length) return null

    let entriesTree: CustomizationEntriesTree = {
      version: "v1",
      entries: [],
    }

    anatomies.forEach((anatomy: AnatomyEntity) => {
      const entry: Entry = { key: anatomy.key }
      if (anatomy.caption) entry.caption = anatomy.caption
      if (anatomy.advanced !== null && anatomy.advanced !== undefined)
        entry.advanced = anatomy.advanced
      if (anatomy.priority !== null && anatomy.priority !== undefined && anatomy.priority >= 0)
        entry.priority = anatomy.priority
      entriesTree.entries.push(entry)
    })

    return entriesTree
  }

  return {
    convertAnatomyTreeToArray,
    convertAnatomiesArrayToEntriesTree,
  }
}
